.header {
    background: #fff;
    border-bottom: 1px solid #f0f0f0;
    left: 0;
    position: fixed;
    right: 0;
    top: 0;
    height: 60px;

    .header-left {
        float: left;
        height: 60px;
        position: relative;
        text-align: center;
        width: 240px;
        -webkit-transition: all 0.4s ease-in-out;
        -ms-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
        padding: 0px 3px 0px 20px;
    }

    .header-left-inactive {
        float: left;
        height: 60px;
        position: relative;
        text-align: center;
        width: 20px;
        -webkit-transition: all 0.4s ease-in-out;
        -ms-transition: all 0.4s ease-in-out;
        transition: all 0.4s ease-in-out;
        padding: 0 20px;

        >img {
            width: 50px;
            height: 50px;
        }
    }

    .nav {

        display: flex;
        flex-wrap: wrap;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;

    }

    .navmenu {
        align-items: center;
        color: #333333;
        display: inline-flex;
        // float: left;
        font-size: 30px;
        height: 60px;
        justify-content: center;

        margin-left: 60px;
        // padding: 0 15px;
    }

    .user-menu {
        float: right;
        margin: 0;
        position: relative;
        padding: 5px;
    }

    .user-menu.nav>li {
        color: #333;
        font-size: 14px;
        line-height: 75px;
        padding: 0 15px;
        height: 50px;
        align-items: center;
        display: flex;
        background-color: #011E60;
        border-radius: 3px;
        cursor: pointer;

        svg {
            color: #fff;
            border: 1px solid #fff;
        }

    }

    .user-menu.nav>li:hover,
    .user-menu.nav>li:focus {
        background-color: #fff;
        border: 2px solid #011E60;
        svg {
            color: #011E60;
            border: 1px solid #011E60;
        }

        span {
            color: #011E60 !important;
        }
    }


}


.header_img {
    -webkit-transition: all 0.4s ease-in-out;
    -ms-transition: all 0.4s ease-in-out;
    transition: all 0.4s ease-in-out;
   cursor: pointer;

}