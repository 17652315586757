.proppage {
    // width: calc(100% - 75px);
    // left: 75px;
    width: 100%;
    height: calc(100% - 60px);
    position: absolute;
    overflow: auto;
    margin-top: 60px;
    padding: 1.875rem 1.875rem 0;
    -webkit-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

    table{
        thead{
            th{
                font-size: 16px !important;
            }
        }
        tbody{
            td{
                font-size: 15px !important;
            }
        }
    }

    // &::-webkit-scrollbar {
    //     display: none;
    // }

    .all{
        background-color: #fff;
        border: 3px solid black;
        border-radius: 5px;
            width: 137px;
    height: 40px;
        margin: 10px;
        float: right;
        font-size: 18px;
        font-weight: 500;
    }

    .isactive{
        background-color: #fff;
        border: 3px solid black;
        border-radius: 5px;
            width: 137px;
    height: 40px;
        margin: 10px;
        float: right;
        font-size: 18px;
        font-weight: 500;

    }

    .sold{
        background-color: #fff;
        border: 3px solid black;
        border-radius: 5px;
            width: 137px;
    height: 40px;
        margin: 10px;
        float: right;
        font-size: 18px;
        font-weight: 500;
    }
    .active{
        background-color:black;
        border-radius: 5px;
            width: 137px;
            color: #fff;
    height: 40px;
        margin: 10px;
        float: right;
        font-size: 18px;
        font-weight: 500;

    }

}


.proppage-sa {
    width: calc(100% - 240px);
    left: 240px;
    height: calc(100% - 60px);
    position: absolute;
    overflow: auto;
    margin-top: 60px;
    padding: 1.875rem 1.875rem 0;
    -webkit-transition: all 0.2s ease-in-out;
    -ms-transition: all 0.2s ease-in-out;
    transition: all 0.2s ease-in-out;

    // &::-webkit-scrollbar {
    //     display: none;
    // }


}


.logout_popup{
    z-index: 999;
    position: fixed;
    background-color: rgba(0, 0, 0, 0.7);
    width: 100%;
    height: 100%;
    display: flex;
    justify-content: center;
    align-items: center;
.cookiesContent {
    width: 460px;
    display: flex;
    flex-direction: column;
    align-items: center;
    background-color: #fff;
    color: #000;
    text-align: center;
    border-radius: 20px;
    padding: 30px 30px 30px;
    button.close {
      width: 30px;
      font-size: 20px;
      color: #c0c5cb;
      align-self: flex-end;
      background-color: transparent;
      border: none;
      margin-bottom: 10px;
    }
    img {
        margin-bottom: 15px;
border-radius: 5px;
width: 138px;

    }
    p {
      margin-bottom: 40px;
      font-size: 18px;
    }
    button.accept {
      background-color: rgb(27,90,144);
      border: none;
      border-radius: 5px;
      width: 200px;
      padding: 14px;
      font-size: 16px;
      color: white;
      box-shadow: 0px 6px 18px -5px rgb(27,90,144),1;
    }
  }
}