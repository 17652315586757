table {
    border-collapse: collapse;
    border-spacing: 0;
    width: 100%;
    height: calc(100%-1px);
    transition: all .3s cubic-bezier(.35, 0, .25, 1);

}
 thead tr{
    background-color: #f8f9fa !important;
    min-height: 48px;

}

table th, table td {
    // text-align: center;
    padding: 12px;
    border-bottom: 1px solid rgba(0,0,0,.12);
    color: rgba(0,0,0,.87);
    font-size: 14px;
}
 
// table tr:nth-child(even) {
//     background-color: ;
// }
 
table tr:hover {
    background-color: white;
}
 
table th {
    padding-top: 12px;
    padding-bottom: 12px;
    // background-color: white;
    // text-align: center;
    color: black !important;
    font-size: 14px;
    font-weight: 600;
    border: 0;
    color: rgba(0,0,0,.54);
}

tbody tr{
    background-color: white ;
    min-height: 48px;

    &:hover{
        background-color: #ECECEC;
    }

}

.buttonwrapper{
    width: 200px;
}

*{
    font-family: "Mada", sans-serif;
    font-size: inherit;
    font-weight: 400;
}





.css-1rxz5jq-MuiSelect-select-MuiInputBase-input-MuiInput-input{
     &:focus{
        background-color: #fff !important;
        padding: 0;
     }
}