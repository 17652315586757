.propertyform {
  // width: calc(100% - 75px);
  // left: 75px;
  width: 100%;
  height: calc(100% - 60px);
  position: absolute;
  overflow: auto;
  margin-top: 60px;
  padding: 1.875rem 1.875rem 0;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  // &::-webkit-scrollbar {
  //   display: none;
  // }
}

.Cropper_Wrapper {
  position: fixed;
  background-color: rgba(0, 0, 0, 0.4);
  left: 0;
  top: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  z-index: 9999;
  width: 100%;
  height: 100%;
  flex-direction: column;

  .Cropper_Inner_Wrapper {
    // width: 800px;
    width: 60vw;
    height: fit-content;
    background-color: #ffff;
    padding: 10px;
    overflow:auto;

    img {
      border: 2px solid #ddd;
    }
  }
  .CropperFooter {
    background-color: #fff;
    // width: 800px;
    width: 60vw;
    padding: 10px;

    button {
      padding: 5px;
      width: 100px;
      border: 0px;
      color: red;
      border: 1px solid red;
      border-radius: 5px;
      background-color: #fff;

      &:hover {
        background-color: red;
        color: #fff;
      }

      &:nth-child(even) {
        float: right;
        color: #011e60;
        background-color: #fff;
        border: 1px solid #011e60;
        border-radius: 5px;

        &:hover {
          background-color: #011e60;
          color: #fff;
        }
      }
    }
  }
}

// .ReactCrop__child-wrapper {
//   max-width: 100%;
//   max-height: 200px;
//   object-fit: contain;
// }

.form-label {
  font-weight: 700;
  color: #011e60 !important;
}
#validationCustom03 {
  padding-left: 50px;
}
.pound {
  position: absolute;
  padding: 7px;
  padding-left: 15px;
  padding-right: 15px;
  background-color: grey;
  color: #fff;
  margin-top: 33px;
  margin-left: -38px;
}

.nearestschool {
  p {
    font-weight: 600 !important;
    font-size: 14px !important;
  }
}

h5 {
  font-size: 17px !important;
  font-weight: 600;
  color: #011e60 !important;
}

.propertyform-sa {
  width: calc(100% - 240px);
  left: 240px;
  height: calc(100% - 60px);
  position: absolute;
  overflow: auto;
  margin-top: 60px;
  padding: 1.875rem 1.875rem 0;
  -webkit-transition: all 0.2s ease-in-out;
  -ms-transition: all 0.2s ease-in-out;
  transition: all 0.2s ease-in-out;

  // &::-webkit-scrollbar {
  //   display: none;
  // }
}

.FormErrors {
  font-size: 12px;
  color: red;
  width: 70%;
  font-weight: 500;
  // font-family: "newPoppins";
  margin-top: 5px;
  line-height: normal;

  .hide {
    display: none;
  }

  .show {
    display: inline;
  }
}

.nearestschool {
  display: flex;
  margin: 10px;

  &.none {
    input {
      border-bottom-left-radius: 5px;
      border-top-left-radius: 5px;
    }
  }

  .pound {
    position: relative;
    margin-top: 0px;
    height: 40px;
    margin-left: 2px;
    border-bottom-left-radius: 5px;
    border-top-left-radius: 5px;
  }

  input {
    border-bottom-left-radius: 0px;
    border-top-left-radius: 0px;
  }

  .type {
    padding: 10px;
  }

  .distance {
    padding: 10px;
  }

  .ofsted {
    padding: 10px;
  }

  .btn {
    margin-left: 10px;
    border: 1px solid #dee2e6;
    height: 40px;
    // padding: 10px;
    width: 50px;
  }
}

@media screen and (min-width: 768px) and (max-width: 1400px) {
}

.form-control {
  display: block;
  width: 91%;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: none;
  color: #333;
  font-size: 15px;
  height: 40px;
}

.form-control1 {
  display: block;
  width: 91%;
  padding: 0.375rem 0.75rem;
  font-weight: 400;
  line-height: 1.5;
  color: #495057;
  background-color: #fff;
  background-clip: padding-box;
  border: 1px solid #ced4da;
  border-radius: 0.25rem;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  box-shadow: none;
  color: #333;
  font-size: 15px;

  &:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }
}

.react-tagsinput-language {
  background-color: #fff;
  border: 1px solid #ced4da;
  overflow: hidden;

  border-radius: 0.375rem;
  padding: 5px;
}

.react-tagsinput-doctor {
  background-color: #fff;
  border: 1px solid #ced4da;
  overflow: hidden;
  border-radius: 0.375rem;
  padding: 5px;
}

.react-tagsinput--focused {
  color: #212529;
  background-color: #fff;
  border-color: #86b7fe;
  outline: 0;
  box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
}

.react-tagsinput-tag {
  align-items: center;
  background: #4d9bd7;
  border-radius: 3px;
  color: #fff;
  display: inline-flex;
  font-size: 13px;
  line-height: 19px;
  margin-bottom: 5px;
  margin-right: 5px;
  padding: 4px 10px;
}

.react-tagsinput-remove {
  cursor: pointer;
  font-weight: bold;
  color: white;
  text-decoration: none;
}

.react-tagsinput-tag a::before {
  content: url(data:image/svg+xml,%3Csvg%20viewBox%3D%220%200%2096%2096%22%20%20%20%20xmlns%3D%22http%3A%2F%2Fwww.w3.org%2F2000%2Fsvg%22%3E%20%20%20%20%3Cstyle%20type%3D%22text%2Fcss%22%3E%20%20%20%20%20%20%20%20.st0%7B%20%20%20%20%20%20%20%20%20%20%20%20fill%3A%23fff%3B%20%20%20%20%20%20%20%20%7D%20%3C%2Fstyle%3E%20%20%20%20%3Cg%3E%20%20%20%20%20%20%20%20%3Cpath%20class%3D%22st0%22%20d%3D%22M48%2C0A48%2C48%2C0%2C1%2C0%2C96%2C48%2C48.0512%2C48.0512%2C0%2C0%2C0%2C48%2C0Zm0%2C84A36%2C36%2C0%2C1%2C1%2C84%2C48%2C36.0393%2C36.0393%2C0%2C0%2C1%2C48%2C84Z%22%2F%3E%20%20%20%20%20%20%20%20%3Cpath%20class%3D%22st0%22%20d%3D%22M64.2422%2C31.7578a5.9979%2C5.9979%2C0%2C0%2C0-8.4844%2C0L48%2C39.5156l-7.7578-7.7578a5.9994%2C5.9994%2C0%2C0%2C0-8.4844%2C8.4844L39.5156%2C48l-7.7578%2C7.7578a5.9994%2C5.9994%2C0%2C1%2C0%2C8.4844%2C8.4844L48%2C56.4844l7.7578%2C7.7578a5.9994%2C5.9994%2C0%2C0%2C0%2C8.4844-8.4844L56.4844%2C48l7.7578-7.7578A5.9979%2C5.9979%2C0%2C0%2C0%2C64.2422%2C31.7578Z%22%2F%3E%20%20%20%20%3C%2Fg%3E%3C%2Fsvg%3E);

  cursor: pointer;
  float: right;
  height: 13px;
  margin-left: 5px;
  width: 13px;
}

.react-tagsinput-input {
  background: transparent;
  border: 0;
  color: #777;
  // font-family: sans-serif;
  // font-size: 12px;
  // font-weight: 400;
  // margin-bottom: 6px;
  // margin-top: 1px;
  // margin-left: -4px;
  // outline: none;
  // padding: 0px;
  // width: 120px;
}

input[type="number"]::-webkit-inner-spin-button,
input[type="number"]::-webkit-outer-spin-button {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  margin: 0;
}

.completed_project {
  border-top: 2px solid black;
  padding-top: 30px;
  margin-top: 30px;
}
